import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/layouts/Default/index.js";
import { Accordion, Paragraph } from '@cheapreats/react-ui';
export const metadata = {
  title: 'Support'
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  metadata,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`CheaprEats | Support Page`}</h1>
    <h2>{`Contact Us`}</h2>
    <p><strong parentName="p">{`We're listening!`}</strong>{` Have a feature you'd like implemented? Contact us today!
We'd love to hear from you, whether it's a feature request, a message about
something you love or even a bug request if you find one!`}</p>
    <p>{`Get in touch at `}<a parentName="p" {...{
        "href": "mailto:hello@cheapreats.com"
      }}>{`hello@cheapreats.com`}</a></p>
    <h2>{`Customer FAQ`}</h2>
    <p>{`Questions you may have for and/or about us as a current or potential Customer.`}</p>
    <Accordion tag='h3' mdxType="Accordion">
    <section data-header='Is Cheapreats Available in my area?'>
        <Paragraph mdxType="Paragraph">
            CheaprEats is currently only available at University of Toronto,
            Scarborough. However, we are a growing company, signup for our
            newsletter to get notified when CheaprEats comes to your area.
        </Paragraph>
    </section>
    <section data-header='Do I need a credit card or debit card to use CheaprEats?'>
        <Paragraph mdxType="Paragraph">
            Yes, we require you to have a credit card or debit card
            before you can place any orders.
        </Paragraph>
    </section>
    </Accordion>
    <h2>{`Vendor FAQ`}</h2>
    <p>{`Questions you may have for and/or about us as a current or potential vendor.`}</p>
    <Accordion tag='h3' mdxType="Accordion">
    <section data-header="What's the CheaprEats ordering process?">
        <Text as='ol' mdxType="Text">
            <li>
                A customer will open the CheaprEats app on their mobile phone, browse
                your new visual menu, selects a meal, provides a tip and then
                prepays right from their phone!
            </li>
            <li>
                On the POS system your employees can choose to accept/cancel the order
                and in one click let the customer know when the food will be ready.
            </li>
            <li>
                Your employee after accepting the order will then proceed to
                complete the order and place it at the pickup station.
            </li>
            <li>
                The customer will at the corresponding pickup time, arrive at
                your store and collect their order.
            </li>
        </Text>
    </section>
    <section data-header='How do I get the POS system?'>
        <Text as='p' mdxType="Text">
            Our POS System can be used on the following devices
            or you can request a special tablet from us:
        </Text>
        <Text as='ul' mdxType="Text">
            <li>Any Mobile Phone (The Web + App Support Available)</li>
            <li>Any Tablet Devices (The Web + App Support Available)</li>
            <li>Your Computer (The Web)</li>
        </Text>
    </section>
    </Accordion>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      